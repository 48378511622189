import { SandboxAction } from "../../reducers/SandboxActions";
import { IEntityProperties } from "../../../../components/graph-builder/types/GraphTypes";

export type ResultsAction = {
  type: "RUN_FINISHED";
  payload: { results: IEntityProperties[]; error?: string };
};

export interface IResultsState {
  data: IEntityProperties[];
  error: string;
  hasRun: boolean;
}

export const initialState = {
  data: [] as IEntityProperties[],
  error: "",
  hasRun: false,
};

export const reducer = (action: SandboxAction, state: IResultsState) => {
  switch (action.type) {
    case "RUN_FINISHED":
      return {
        ...state,
        data: action.payload.results,
        error: action.payload.error || "",
        hasRun: true,
      };
    case "CLEAR_WORKSPACE":
      return initialState;
  }

  return state;
};
